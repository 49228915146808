import api from './apiClient';
import { getStoredItem } from './storageService';

export const loginUser = async (login: string, password: string) => {
  const response = await api.post('/auth/login', {
    login,
    password,
  });
  return response.data;
};

export const logoutUser = async () => {
  const response = await api.post('/auth/log-out', {
    'refresh-token': getStoredItem<string>('refresh-token'),
  });
  return response.data;
};

export default {
  loginUser,
  logoutUser,
};
