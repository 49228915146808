import { createSelector } from 'reselect';
import { RootState } from '../index';
import { InboundsState } from '../slices/inboundsSlice';

const selectInboundsState = (state: RootState): InboundsState => state.inboundsState;

export const selectInbounds = createSelector([selectInboundsState], (inboundsState) => inboundsState.inbounds);

export const selectInboundsError = createSelector([selectInboundsState], (inboundsState) => inboundsState.getInboundsError);

export const selectTotalRowInbounds = createSelector([selectInboundsState], (inboundsState) => inboundsState.totalRows);

export const selectIsInboundsLoading = createSelector([selectInboundsState], (inboundsState) => inboundsState.isInboundsLoading);

export const selectIsFinishInboundLoading = createSelector([selectInboundsState], (inboundsState) => inboundsState.isFinishInboundLoading);

export const selectFinishInboundError = createSelector([selectInboundsState], (inboundsState) => inboundsState.finishInboundError);
