import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginUser, logoutUser } from '../../services/authService';
import { removeStoredItem, storeItem } from '../../services/storageService';

export interface AuthState {
  isAuthenticated: boolean;
  isLoggingIn: boolean;
  error: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isLoggingIn: false,
  error: null,
};

export const login = createAsyncThunk('user/login', async ({ email, password }: { email: string; password: string }, thunkAPI) => {
  try {
    const response = await loginUser(email, password);
    const accessToken = response['access-token'];
    const refreshToken = response['refresh-token'];

    storeItem('access-token', accessToken);
    storeItem('refresh-token', refreshToken);
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error?.response?.data || 'Login failed');
  }
});

export const logout = createAsyncThunk('user/logout', async (_, thunkAPI) => {
  try {
    await logoutUser();

    removeStoredItem('access-token');
    removeStoredItem('refresh-token');

    return true;
  } catch (error: unknown) {
    return thunkAPI.rejectWithValue('Logout failed');
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoggingIn = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state) => {
        state.isAuthenticated = true;
        state.isLoggingIn = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoggingIn = false;
        state.error = action.payload as string;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isAuthenticated = false;
      });
  },
});

export default authSlice.reducer;
