import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Input } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { GridColDef, GridFilterItem, GridPaginationModel, GridSortItem } from '@mui/x-data-grid-pro';
import { CONSTANTS } from '../../models';
import { SharedTable } from '../../components/sharedTable';
import { useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import {
  selectOutbounds,
  selectTotalRowOutbounds,
  selectWaitOutboundMesure,
  selectWaitOutboundPack,
  selectWaitOutboundSend,
  selectWaitOutboundsTableFetch,
} from '../../store/selectors/outboundsSelector';
import { getOutbounds, measureOutbounds, sendOutbounds, packOutbounds } from '../../store/slices/outboundsSlice';
import { getStoredItem, removeStoredItem, storeItem } from '../../services/storageService';
import { CargoType } from '../../models/Outbound';
import { getStateSnapshotFromLocalStorage } from '../../utils/state';

const tableName = 'outbounds';
const defaultFilter: GridFilterItem[] = [];
const defaultSorting: GridSortItem[] = [{ field: 'id', sort: 'desc' }];
const defaultPagination: Partial<GridPaginationModel> = { page: 0, pageSize: 25 };
const hiddenFiltering: GridFilterItem[] = [
  {
    id: 'cargoTypeNotSendback',
    field: 'cargo_type',
    operator: 'not',
    value: 'sendback',
  },
];

export const OutboundsPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const outbounds = useSelector(selectOutbounds);
  const isLoading = useSelector(selectWaitOutboundsTableFetch);
  const waitMeasure = useSelector(selectWaitOutboundMesure);
  const waitPack = useSelector(selectWaitOutboundPack);
  const waitSend = useSelector(selectWaitOutboundSend);
  const totalRows = useSelector(selectTotalRowOutbounds);

  const [isDataNededToBeFetched, setIsDataNededToBeFetched] = useState(true);
  const [isPaginationChange, setIsPaginationChange] = useState(false);

  const [selectedOutboundIds, setSelectedOutboundIds] = useState<number[]>([]);

  const [volume, setVolume] = useState<number>(0);
  const [weight, setWeight] = useState<number>(0);

  const cargoTypeTitlePair: { [key in CargoType]: string } = {
    aero_general: 'Общий',
    aero_dangerous: 'Опасный',
    sendback: 'Возврат',
    car: 'Автомобиль',
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      hideable: false,
    },
    {
      field: 'cargo_type',
      headerName: 'Способ отправки',
      type: 'singleSelect',
      valueOptions: Object.entries(cargoTypeTitlePair).map((entry) => ({
        label: entry[1],
        value: entry[0],
      })),
    },
    {
      field: 'destination_point_name',
      headerName: 'Место назначения',
      type: 'string',
    },
    {
      field: 'destination_point_region',
      headerName: 'Регион назначения',
      type: 'string',
    },
    {
      field: 'destination_point_city',
      headerName: 'Город назначения',
      type: 'string',
    },
    {
      field: 'destination_point_settlement',
      headerName: 'Поселок назначения',
      type: 'string',
    },
    {
      field: 'destination_point_index',
      headerName: 'Индекс назначения',
      type: 'number',
    },
    {
      field: 'postings_count',
      headerName: 'Отправлений',
      type: 'number',
    },
    {
      field: 'total_weight',
      headerName: 'Вес',
      type: 'number',
    },
    {
      field: 'total_volumetric_weight',
      headerName: 'Объемный вес',
      type: 'number',
    },
    {
      field: 'packed_at',
      headerName: 'Упаковано',
      type: 'dateTime',
    },
    {
      field: 'sent_at',
      headerName: 'Отправлено',
      type: 'dateTime',
    },
    {
      field: 'delivered_at',
      headerName: 'Доставлено',
      type: 'dateTime',
    },
  ];

  const handleSelectionChange = useCallback(
    (pageSelectedRowIds: number[]) => {
      if (isPaginationChange) {
        setIsPaginationChange(false);
        return;
      }

      setSelectedOutboundIds(pageSelectedRowIds);
      storeItem(CONSTANTS.selectedOutboundIds, pageSelectedRowIds);

      const selectedOutbounds = outbounds.filter((outbound) => pageSelectedRowIds.includes(outbound.id));
      const storedSelectedRowVolumes = getStoredItem<number>('selectedOutboundVolumes') ?? 0;
      const storedSelectedRowWeights = getStoredItem<number>('selectedOutboundWeights') ?? 0;
      const selectedOutboundVolumes = selectedOutbounds.reduce((sum, outbound) => sum + outbound.total_volumetric_weight, 0);
      const selectedOutboundWeights = selectedOutbounds.reduce((sum, outbound) => sum + outbound.total_weight, 0);

      setWeight(storedSelectedRowVolumes + selectedOutboundVolumes);
      setVolume(storedSelectedRowWeights + selectedOutboundWeights);
    },
    [outbounds, selectedOutboundIds, isPaginationChange]
  );

  const handlePaginationChange = useCallback(() => {
    setIsPaginationChange(true);
    setIsDataNededToBeFetched(true);
  }, [weight, volume]);

  const clearSelectedOutbounds = () => {
    removeStoredItem(CONSTANTS.selectedOutboundIds);
    removeStoredItem('selectedOutboundWeights');
    removeStoredItem('selectedOutboundVolumes');
    setSelectedOutboundIds([]);
    setVolume(0);
    setWeight(0);
  };

  const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWeight(Number(event.target.value));
    storeItem('selectedOutboundWeights', weight);
  };

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVolume(Number(event.target.value));
    storeItem('selectedOutboundVolumes', volume);
  };

  const handleMeasure = useCallback(async () => {
    const outbounds = selectedOutboundIds.map((id) => ({ id }));
    const resultAction = await dispatch(
      measureOutbounds({
        outbounds: outbounds,
        volume: volume,
        weight: weight,
      })
    );

    if (measureOutbounds.fulfilled.match(resultAction)) {
      // const mapped = resultAction.payload;
      // dispatch(sendOutboundMeasurementsReducer(mapped));
      toast.success(resultAction.payload['message:']);
      clearSelectedOutbounds();
    }
  }, [dispatch, selectedOutboundIds, weight, volume]);

  const handlePack = useCallback(async () => {
    const outbounds = selectedOutboundIds.map((id) => ({ id }));
    const resultAction = await dispatch(packOutbounds(outbounds));

    if (packOutbounds.fulfilled.match(resultAction)) {
      toast.success(resultAction.payload['message:']);
      clearSelectedOutbounds();
    }
  }, [dispatch, selectedOutboundIds]);

  const handleSend = useCallback(async () => {
    const wrappedIds = selectedOutboundIds.map((id) => ({ id }));
    const resultAction = await dispatch(sendOutbounds(wrappedIds));

    if (sendOutbounds.fulfilled.match(resultAction)) {
      toast.success('Успешно обновлены!');
      clearSelectedOutbounds();
      dispatchGetOutbounds();
    }
  }, [dispatch, selectedOutboundIds, weight, volume]);

  const dispatchGetOutbounds = useCallback(async () => {
    const snapshotState = await getStateSnapshotFromLocalStorage(tableName);
    const resultAction = await dispatch(
      getOutbounds({
        filtering: [snapshotState?.filter?.filterModel?.items ?? defaultFilter, hiddenFiltering].flat(),
        sorting: snapshotState?.sorting?.sortModel ?? defaultSorting,
        ...(snapshotState?.pagination?.paginationModel ?? defaultPagination),
      })
    );

    if (getOutbounds.fulfilled.match(resultAction)) {
      setIsDataNededToBeFetched(false);
      const storedSelectedRowIds = getStoredItem<number[]>(CONSTANTS.selectedOutboundIds);
      if (storedSelectedRowIds) {
        setSelectedOutboundIds(storedSelectedRowIds);
        setVolume(getStoredItem<number>('selectedOutboundVolumes') ?? 0);
        setWeight(getStoredItem<number>('selectedOutboundWeights') ?? 0);
      }
    }
  }, [isDataNededToBeFetched]);

  const handleDataQueryChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
  }, []);

  useEffect(() => {
    if (isDataNededToBeFetched) {
      dispatchGetOutbounds();
    }
  }, [isDataNededToBeFetched]);

  return (
    <div>
      <ToastContainer />
      {selectedOutboundIds.length > 0 && (
        <>
          <Box sx={{ display: 'flex', gap: '12px' }}>
            <Button
              variant="contained"
              color="info"
              onClick={handlePack}
              disabled={waitPack}
              startIcon={waitPack ? <CircularProgress size={16} /> : null}
            >
              {waitPack ? 'Обновление...' : 'Упаковать'}
            </Button>
            <Button
              variant="contained"
              color="info"
              onClick={handleSend}
              disabled={waitSend}
              startIcon={waitSend ? <CircularProgress size={16} /> : null}
            >
              {waitSend ? 'Запись отправки...' : 'Записать отправку'}
            </Button>
          </Box>
          <Box sx={{ display: 'flex', gap: '12px' }}>
            <Input type="number" placeholder="Вес" value={weight} onChange={handleWeightChange} />
            <Input type="number" placeholder="Объем" value={volume} onChange={handleVolumeChange} />
            <Button
              variant="contained"
              color="info"
              onClick={handleMeasure}
              disabled={waitMeasure}
              startIcon={waitMeasure ? <CircularProgress size={16} /> : null}
            >
              {waitMeasure ? 'Обновление...' : 'Отправить замеры'}
            </Button>
          </Box>
        </>
      )}
      <SharedTable
        columns={columns}
        loading={isLoading}
        onFilterModelChange={handleDataQueryChange}
        onPaginationChange={handlePaginationChange}
        onSelectionChange={handleSelectionChange}
        onSortModelChange={handleDataQueryChange}
        rowCount={totalRows}
        rows={outbounds}
        selectedRowIds={selectedOutboundIds}
        tableName={tableName}
      />
    </div>
  );
};
