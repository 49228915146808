import React, { useCallback, useEffect, useState } from 'react';
import { SharedTable } from '../../components/sharedTable';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { GridColDef, GridFilterItem, GridPaginationModel, GridRenderCellParams, GridSortItem } from '@mui/x-data-grid-pro';
import { useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { selectIsDeleteUsersLoading, selectIsUsersLoading, selectTotalRowUsers, selectUsers } from '../../store/selectors/usersSelectors';
import { deleteUsers, getUsers } from '../../store/slices/usersSlice';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getStoredItem, removeStoredItem, storeItem } from '../../services/storageService';
import { CONSTANTS } from '../../models/Constants';
import { getStateSnapshotFromLocalStorage } from '../../utils/state';

const tableName = 'users';
const defaultSorting: GridSortItem[] = [{ field: 'id', sort: 'desc' }];
const defaultPagination: Partial<GridPaginationModel> = { page: 0, pageSize: 25 };
const defaultFilter: GridFilterItem[] = [
  {
    field: 'active',
    operator: 'equals',
    value: true,
  },
];

export const UsersPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const users = useSelector(selectUsers);
  const isDeleteUsersLoading = useSelector(selectIsDeleteUsersLoading);
  const isUsersLoading = useSelector(selectIsUsersLoading);
  const totalRows = useSelector(selectTotalRowUsers);

  const [isDataNededToBeFetched, setIsDataNededToBeFetched] = useState(true);
  const [isPaginationChange, setIsPaginationChange] = useState(false);

  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

  const dispatchGetUsers = useCallback(async () => {
    const snapshotState = await getStateSnapshotFromLocalStorage(tableName);
    const resultAction = await dispatch(
      getUsers({
        filtering: snapshotState?.filter?.filterModel?.items ?? defaultFilter,
        sorting: snapshotState?.sorting?.sortModel ?? defaultSorting,
        ...(snapshotState?.pagination?.paginationModel ?? defaultPagination),
      })
    );

    if (getUsers.fulfilled.match(resultAction)) {
      setIsDataNededToBeFetched(false);
      const storedSelectedRowIds = getStoredItem<number[]>(CONSTANTS.selectedUserIds);
      if (storedSelectedRowIds) {
        setSelectedUserIds(storedSelectedRowIds);
      }
    }
  }, [defaultPagination, defaultSorting, defaultFilter, dispatch]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
    },
    {
      field: 'login',
      headerName: 'Логин',
      type: 'string',
    },
    {
      field: 'active',
      headerName: 'Активно',
      type: 'boolean',
    },
    {
      field: 'actions',
      headerName: 'Редактировать',
      renderCell: (params: GridRenderCellParams) => (
        <IconButton color="primary" onClick={() => navigate(`/users/edit/${params.row.id}`)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const handleSelectionChange = useCallback(
    (pageSelectedRowIds: number[]) => {
      if (isPaginationChange) {
        setIsPaginationChange(false);
        return;
      }

      setSelectedUserIds(pageSelectedRowIds);
      storeItem(CONSTANTS.selectedUserIds, pageSelectedRowIds);
    },
    [selectedUserIds, isPaginationChange]
  );

  const handlePaginationChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
    setIsPaginationChange(true);
  }, []);

  const handleAddUser = () => {
    navigate('/users/create');
  };

  const handleDelete = useCallback(async () => {
    const resultAction = await dispatch(deleteUsers(selectedUserIds as number[]));

    if (deleteUsers.fulfilled.match(resultAction)) {
      toast.success('Пользователи успешно удалены!');
      setSelectedUserIds([]);
      dispatchGetUsers();
      removeStoredItem(CONSTANTS.selectedUserIds);
    } else {
      toast.error(`Ошибка: ${resultAction.error}`);
    }
  }, [dispatch, selectedUserIds]);

  const handleDataQueryChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
  }, []);

  useEffect(() => {
    if (isDataNededToBeFetched) {
      dispatchGetUsers();
    }
  }, [isDataNededToBeFetched]);

  return (
    <div>
      <ToastContainer />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px',
        }}
      >
        <Typography variant="h4">Пользователи</Typography>
        <Box sx={{ display: 'flex', gap: '12px' }}>
          {selectedUserIds.length > 0 && (
            <Button
              variant="contained"
              color="error"
              onClick={handleDelete}
              disabled={isDeleteUsersLoading}
              startIcon={isDeleteUsersLoading ? <CircularProgress size={16} /> : null}
            >
              {isDeleteUsersLoading ? 'Удаления...' : 'Удалить выбранные'}
            </Button>
          )}
          <Button variant="contained" color="primary" onClick={handleAddUser}>
            Добавить
          </Button>
        </Box>
      </Box>
      <SharedTable
        columns={columns}
        loading={isUsersLoading}
        disableColumnSelector={true}
        onFilterModelChange={handleDataQueryChange}
        onPaginationChange={handlePaginationChange}
        onSelectionChange={handleSelectionChange}
        onSortModelChange={handleDataQueryChange}
        rowCount={totalRows}
        rows={users}
        selectedRowIds={selectedUserIds}
        tableName={tableName}
      />
    </div>
  );
};
