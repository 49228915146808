import axios from 'axios';
import { getStoredItem, storeItem, removeStoredItem } from './storageService';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Note: Adds access token to every request
api.interceptors.request.use(
  (config) => {
    const accessToken = getStoredItem<string>('access-token');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Note: Handles token expiration and refresh
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = getStoredItem<string>('refresh-token');

      if (refreshToken) {
        try {
          const { data } = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/refresh`, {
            'refresh-token': refreshToken,
          });

          const { accessToken: newAccessToken, refreshToken: newRefreshToken } = data;

          storeItem('access-token', newAccessToken);
          storeItem('refresh-token', newRefreshToken);

          // Note: Retries original request with new access token
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return api(originalRequest);
        } catch (refreshError) {
          removeStoredItem('access-token');
          removeStoredItem('refresh-token');
          window.location.href = '/login'; // Note: Redirects to login if refresh fails
        }
      } else {
        removeStoredItem('access-token');
        removeStoredItem('refresh-token');
        window.location.href = '/login'; // Note: If no refresh token, redirects to login
      }
    }

    return Promise.reject(error);
  }
);

export default api;
