import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addNewDictionary,
  deleteDictionariesByIds,
  fetchDictionaryById,
  fetchDictionaries,
  updateExistingDictionary,
} from '../../services/dictionaryService';
import { ApiError, Dictionary } from '../../models';

export interface DictionariesState {
  dictionaries: Dictionary[];
  totalRow: number;
  isDictionariesLoading: boolean;
  getDictionariesError: string | null;
  isAddDictionaryLoading: boolean;
  addNewDictionaryError?: string | null;
  selectedDictionaryToUpdate?: Dictionary | null;
  getDictionaryByIdError?: string | null;
  isUpdateDictionaryLoading: boolean;
  updateDictionaryError?: string | null;
  isGetDictionaryByIdLoading: boolean;
  isDeleteDictionariesLoading: boolean;
  deleteDictionariesError?: ApiError | null;
}

const initialState: DictionariesState = {
  dictionaries: [],
  isDictionariesLoading: false,
  totalRow: 0,
  getDictionariesError: null,
  isAddDictionaryLoading: false,
  selectedDictionaryToUpdate: null,
  getDictionaryByIdError: null,
  isUpdateDictionaryLoading: false,
  isGetDictionaryByIdLoading: false,
  isDeleteDictionariesLoading: false,
  deleteDictionariesError: null,
};

export const getDictionaries = createAsyncThunk('words/table', async (payload: any, thunkAPI) => {
  try {
    const res = await fetchDictionaries(payload);
    return res;
  } catch (error: unknown) {
    return thunkAPI.rejectWithValue('Failed to fetch dictionaries');
  }
});

export const getDictionaryById = createAsyncThunk('words/getDictionaryById', async (id: number, thunkAPI) => {
  try {
    const response = await fetchDictionaryById(id);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to fetch dictionary');
  }
});

export const addDictionary = createAsyncThunk('words/addDictionary', async (dictionary: Dictionary, thunkAPI) => {
  try {
    const response = await addNewDictionary(dictionary);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to create dictionary');
  }
});

export const updateDictionary = createAsyncThunk(
  'words/updateDictionary',
  async ({ id, dictionary }: { id: number; dictionary: Dictionary }, thunkAPI) => {
    try {
      const response = await updateExistingDictionary([{ id, ...dictionary }]);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Failed to update dictionary');
    }
  }
);

export const deleteDictionaries = createAsyncThunk<number[], number[], { rejectValue: ApiError }>(
  'words/deleteDictionaries',
  async (dictionaryIds: number[], thunkAPI) => {
    try {
      await deleteDictionariesByIds(dictionaryIds);
      return dictionaryIds;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to delete dictionaries');
    }
  }
);

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {
    clearDeleteDictionariesError(state) {
      state.deleteDictionariesError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDictionaries.pending, (state) => {
        state.isDictionariesLoading = true;
      })
      .addCase(getDictionaries.fulfilled, (state, action) => {
        const res = action.payload;
        state.isDictionariesLoading = false;
        state.dictionaries = res.rows;
        state.totalRow = res?.totalRows;
      })
      .addCase(getDictionaries.rejected, (state, action) => {
        state.isDictionariesLoading = false;
        state.getDictionariesError = action.payload as string;
      })

      // Handle getDictionaryById
      .addCase(getDictionaryById.pending, (state) => {
        state.isGetDictionaryByIdLoading = true;
      })
      .addCase(getDictionaryById.fulfilled, (state, action) => {
        state.isGetDictionaryByIdLoading = false;
        state.selectedDictionaryToUpdate = action.payload;
      })
      .addCase(getDictionaryById.rejected, (state, action) => {
        state.isGetDictionaryByIdLoading = false;
        state.getDictionaryByIdError = action.payload as string;
      })

      // Handle createDictionary
      .addCase(addDictionary.pending, (state) => {
        state.isAddDictionaryLoading = true;
      })
      .addCase(addDictionary.fulfilled, (state, action) => {
        state.isAddDictionaryLoading = false;
      })
      .addCase(addDictionary.rejected, (state, action) => {
        state.isAddDictionaryLoading = false;
        state.addNewDictionaryError = action.payload as string;
      })

      // Handle updateDictionary
      .addCase(updateDictionary.pending, (state) => {
        state.isUpdateDictionaryLoading = true;
      })
      .addCase(updateDictionary.fulfilled, (state, action) => {
        state.isUpdateDictionaryLoading = false;
      })
      .addCase(updateDictionary.rejected, (state, action) => {
        state.isUpdateDictionaryLoading = false;
        state.updateDictionaryError = action.payload as string;
      })

      // Handle deleteDictionaries
      .addCase(deleteDictionaries.pending, (state) => {
        state.isDeleteDictionariesLoading = true;
        state.deleteDictionariesError = null;
      })
      .addCase(deleteDictionaries.fulfilled, (state) => {
        state.isDeleteDictionariesLoading = false;
      })
      .addCase(deleteDictionaries.rejected, (state, action) => {
        state.isDeleteDictionariesLoading = false;
        state.deleteDictionariesError = action.payload || {
          message: 'Failed to delete dictionaries',
        };
      });
  },
});

export const { clearDeleteDictionariesError } = dictionariesSlice.actions;
export default dictionariesSlice.reducer;
