import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchInbounds, finishInbound } from '../../services/inboundService';
import { Inbound } from '../../models/Inbound';

export interface InboundsState {
  inbounds: Inbound[];
  totalRows: number;
  isInboundsLoading: boolean;
  getInboundsError: string | null;
  isInboundsUpdating: boolean;
  getInboundUpdateError: string | null;
  isFinishInboundLoading: boolean;
  finishInboundError: string | null;
}

const initialState: InboundsState = {
  inbounds: [],
  totalRows: 0,
  isInboundsLoading: false,
  getInboundsError: null,
  isInboundsUpdating: false,
  getInboundUpdateError: null,
  isFinishInboundLoading: false,
  finishInboundError: null,
};

export const getInbounds = createAsyncThunk('inbounds/table', async (payload: any, thunkAPI) => {
  try {
    const res = await fetchInbounds(payload);
    return res;
  } catch (error: unknown) {
    return thunkAPI.rejectWithValue('Failed to fetch inbounds');
  }
});

export const finishInbounds = createAsyncThunk('inbounds/finishInbound', async (payload: any, thunkAPI) => {
  try {
    const response = await finishInbound(payload);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to finish inbound');
  }
});

const inboundsSlice = createSlice({
  name: 'inbounds',
  initialState,
  reducers: {
    updateInboundFinishedAt(state, action) {
      const mapped = action.payload;
      state.inbounds = state.inbounds.map((inbound) =>
        mapped[inbound.id]
          ? {
              ...inbound,
              finished_at: mapped[inbound.id],
            }
          : inbound
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInbounds.pending, (state) => {
        state.isInboundsLoading = true;
      })
      .addCase(getInbounds.fulfilled, (state, action) => {
        const res = action.payload;
        state.isInboundsLoading = false;
        state.inbounds = res.rows;
        state.totalRows = res?.totalRows;
      })
      .addCase(getInbounds.rejected, (state, action) => {
        state.isInboundsLoading = false;
        state.getInboundsError = action.payload as string;
      })
      // update finished at
      .addCase(finishInbounds.pending, (state) => {
        state.isFinishInboundLoading = true;
      })
      .addCase(finishInbounds.fulfilled, (state) => {
        state.isFinishInboundLoading = false;
      })
      .addCase(finishInbounds.rejected, (state, action) => {
        state.isFinishInboundLoading = false;
        state.finishInboundError = action.payload as string;
      });
  },
});

export const { updateInboundFinishedAt } = inboundsSlice.actions;
export default inboundsSlice.reducer;
