import { Dictionary } from '../models';
import api from './apiClient';

export const fetchDictionaries = async (payload?: any) => {
  const response = await api.post('/words/table', payload);
  return response.data;
};

export const fetchDictionaryById = (id: number) => {
  return api.get(`/word/${id}`);
};

export const addNewDictionary = (dictionary: Dictionary) => {
  return api.post<Dictionary>('/word', dictionary);
};

export const updateExistingDictionary = (dictionaries: Dictionary[]) => {
  return api.put<Dictionary>(`/words`, dictionaries);
};

export const deleteDictionariesByIds = async (dictionaryIds: number[]) => {
  try {
    return await api.delete(`/words`, { data: dictionaryIds });
  } catch (error: any) {
    throw error.response || { message: 'Unknown error occurred' };
  }
};

export default {
  fetchDictionaries,
  fetchDictionaryById,
  addNewDictionary,
  updateExistingDictionary,
  deleteDictionariesByIds,
};
