import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addNewUser, deleteUsersByIds, fetchUserById, fetchUsers, updateExistingUser } from '../../services/userService';
import { ApiError, User } from '../../models';

export interface UsersState {
  users: User[];
  totalRow: number;
  isUsersLoading: boolean;
  getUsersError: string | null;
  isAddUserLoading: boolean;
  addNewUserError?: string | null;
  selectedUserToUpdate?: User | null;
  getUserByIdError?: string | null;
  isUpdateUserLoading: boolean;
  updateUserError?: string | null;
  isGetUserByIdLoading: boolean;
  isDeleteUsersLoading: boolean;
  deleteUsersError?: ApiError | null;
}

const initialState: UsersState = {
  users: [],
  isUsersLoading: false,
  totalRow: 0,
  getUsersError: null,
  isAddUserLoading: false,
  selectedUserToUpdate: null,
  getUserByIdError: null,
  isUpdateUserLoading: false,
  isGetUserByIdLoading: false,
  isDeleteUsersLoading: false,
  deleteUsersError: null,
};

export const getUsers = createAsyncThunk('users/table', async (payload: any, thunkAPI) => {
  try {
    const res = await fetchUsers(payload);
    return res;
  } catch (error: unknown) {
    return thunkAPI.rejectWithValue('Failed to fetch users');
  }
});

export const getUserById = createAsyncThunk('users/getUserById', async (id: number, thunkAPI) => {
  try {
    const response = await fetchUserById(id);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to fetch user');
  }
});

export const addUser = createAsyncThunk('users/addUser', async (user: User, thunkAPI) => {
  try {
    const response = await addNewUser(user);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to create user');
  }
});

export const updateUser = createAsyncThunk('users/updateUser', async ({ id, user }: { id: number; user: User }, thunkAPI) => {
  try {
    const response = await updateExistingUser({ id, ...user });
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to update user');
  }
});

export const deleteUsers = createAsyncThunk<number[], number[], { rejectValue: ApiError }>(
  'users/deleteUsers',
  async (userIds: number[], thunkAPI) => {
    try {
      await deleteUsersByIds(userIds);
      return userIds;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to delete users');
    }
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearDeleteUsersError(state) {
      state.deleteUsersError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isUsersLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        const res = action.payload;
        state.isUsersLoading = false;
        state.users = res.rows;
        state.totalRow = res?.totalRows;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isUsersLoading = false;
        state.getUsersError = action.payload as string;
      })

      // Handle getUserById
      .addCase(getUserById.pending, (state) => {
        state.isGetUserByIdLoading = true;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.isGetUserByIdLoading = false;
        state.selectedUserToUpdate = action.payload;
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.isGetUserByIdLoading = false;
        state.getUserByIdError = action.payload as string;
      })

      // Handle createUser
      .addCase(addUser.pending, (state) => {
        state.isAddUserLoading = true;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.isAddUserLoading = false;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.isAddUserLoading = false;
        state.addNewUserError = action.payload as string;
      })

      // Handle updateUser
      .addCase(updateUser.pending, (state) => {
        state.isUpdateUserLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isUpdateUserLoading = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isUpdateUserLoading = false;
        state.updateUserError = action.payload as string;
      })

      // Handle deleteUsers
      .addCase(deleteUsers.pending, (state) => {
        state.isDeleteUsersLoading = true;
        state.deleteUsersError = null;
      })
      .addCase(deleteUsers.fulfilled, (state) => {
        state.isDeleteUsersLoading = false;
      })
      .addCase(deleteUsers.rejected, (state, action) => {
        state.isDeleteUsersLoading = false;
        state.deleteUsersError = action.payload || {
          message: 'Failed to delete users',
        };
      });
  },
});

export const { clearDeleteUsersError } = usersSlice.actions;
export default usersSlice.reducer;
