import React, { useCallback, useEffect, useState } from 'react';
import { GridColDef, GridFilterItem, GridPaginationModel, GridRenderCellParams, GridSortItem } from '@mui/x-data-grid-pro';
import { useAppDispatch } from '../../store';
import { getPostings } from '../../store/slices/postingsSlice';
import { CONSTANTS } from '../../models';
import { ToastContainer } from 'react-toastify';
import { Tooltip } from '@mui/material';
import { SharedTable } from '../../components/sharedTable';
import { selectIsPostingsLoading, selectPostings, selectTotalRowPostings } from '../../store/selectors/postingSelector';
import { useSelector } from 'react-redux';
import { getStoredItem, storeItem } from '../../services/storageService';
import { getStateSnapshotFromLocalStorage } from '../../utils/state';

const tableName = 'postings';
const defaultFilter: GridFilterItem[] = [];
const defaultSorting: GridSortItem[] = [{ field: 'id', sort: 'desc' }];
const defaultPagination: Partial<GridPaginationModel> = { page: 0, pageSize: 25 };
const hiddenFiltering: GridFilterItem[] = [
  {
    id: 'cargoTypeNotSendback',
    field: 'cargo_type',
    operator: 'not',
    value: 'sendback',
  },
];

export const PostingsPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const postings = useSelector(selectPostings);
  const totalRows = useSelector(selectTotalRowPostings);
  const isPostingsLoading = useSelector(selectIsPostingsLoading);

  const [isPaginationChange, setIsPaginationChange] = useState(false);
  const [isDataNededToBeFetched, setIsDataNededToBeFetched] = useState(true);

  const [selectedPostingIds, setSelectedPostingIds] = useState<number[]>([]);

  const aerodarCargoTypeTitlePair = {
    aero_general: 'Общий',
    aero_dangerous: 'Опасный',
    car: 'Автомобиль',
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
    },
    {
      field: 'cargo_type',
      headerName: 'Тип перевозки',
      type: 'singleSelect',
      valueOptions: Object.entries(aerodarCargoTypeTitlePair).map((entry) => ({
        label: entry[1],
        value: entry[0],
      })),
    },
    {
      field: 'ozon_destination_point_name',
      headerName: 'Место назначение',
      type: 'string',
    },
    {
      field: 'ozon_destination_point_region',
      headerName: 'Регион назначения',
      type: 'string',
    },
    {
      field: 'ozon_destination_point_city',
      headerName: 'Город назначения',
      type: 'string',
    },
    {
      field: 'ozon_destination_point_settlement',
      headerName: 'Населенный пункт назначения',
      type: 'string',
    },
    {
      field: 'ozon_destination_point_index',
      headerName: 'Индекс назначения',
      type: 'string',
    },
    {
      field: 'ozon_return_point_name',
      headerName: 'Место возврата',
      type: 'string',
    },
    {
      field: 'ozon_exemplars',
      headerName: 'Состав',
      valueGetter: (_, row) =>
        row.ozon_exemplars
          ?.map((o: { name: string }) => o.name)
          .slice(0, 2)
          .join('\n') ?? 'Не указан',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.row.ozon_exemplars?.map((o: { name: string }) => o.name).join('\n') ?? ''}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'danger_class',
      headerName: 'Наш ОГ',
      type: 'number',
    },
    {
      field: 'ozon_danger_class',
      headerName: 'Ozon ОГ',
      type: 'number',
    },
    {
      field: 'ozon_inbound_id',
      headerName: 'Входящая поставка',
      type: 'number',
    },
    {
      field: 'outbound_id',
      headerName: 'Исходящая поставка',
      type: 'number',
    },
    {
      field: 'last_scan_at',
      headerName: 'Последнее сканирование',
      type: 'dateTime',
    },
  ];

  const handleSelectionChange = useCallback(
    (pageSelectedRowIds: number[]) => {
      if (isPaginationChange) {
        setIsPaginationChange(false);
        return;
      }

      setSelectedPostingIds(pageSelectedRowIds);
      storeItem(CONSTANTS.selectedPostingIds, pageSelectedRowIds);
    },
    [isPaginationChange]
  );

  const handlePaginationChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
    setIsPaginationChange(true);
  }, []);

  const dispatchGetPostings = useCallback(async () => {
    const snapshotState = await getStateSnapshotFromLocalStorage(tableName);
    const resultAction = await dispatch(
      getPostings({
        filtering: [snapshotState?.filter?.filterModel?.items ?? defaultFilter, hiddenFiltering].flat(),
        sorting: snapshotState?.sorting?.sortModel ?? defaultSorting,
        ...(snapshotState?.pagination?.paginationModel ?? defaultPagination),
      })
    );

    if (getPostings.fulfilled.match(resultAction)) {
      setIsDataNededToBeFetched(false);
      const storedSelectedRowIds = getStoredItem<number[]>(CONSTANTS.selectedPostingIds);
      if (storedSelectedRowIds) {
        setSelectedPostingIds(storedSelectedRowIds);
      }
    }
  }, [defaultFilter, defaultPagination, defaultSorting, dispatch]);

  useEffect(() => {
    if (isDataNededToBeFetched) {
      dispatchGetPostings();
    }
  }, [isDataNededToBeFetched, defaultFilter, defaultPagination, defaultSorting]);

  const handleDataQueryChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
  }, []);

  return (
    <div>
      <ToastContainer />

      <SharedTable
        columns={columns}
        loading={isPostingsLoading}
        onFilterModelChange={handleDataQueryChange}
        onPaginationChange={handlePaginationChange}
        onSelectionChange={handleSelectionChange}
        onSortModelChange={handleDataQueryChange}
        rowCount={totalRows}
        rows={postings}
        selectedRowIds={selectedPostingIds}
        tableName="postings"
      />
    </div>
  );
};
