import api from './apiClient';

export const fetchInbounds = async (payload?: any) => {
  const response = await api.post('/inbounds/table', payload);
  return response.data;
};

export const finishInbound = (payload: any) => {
  return api.post('/inbounds/finish', payload);
};
