import { User } from '../models';
import api from './apiClient';

export const fetchUsers = async (payload?: any) => {
  const response = await api.post('/users/table', payload);
  return response.data;
};

export const fetchUserById = (id: number) => {
  return api.get(`/user/${id}`);
};

export const addNewUser = (user: User) => {
  return api.post<User>('/user', user);
};

export const updateExistingUser = (user: User) => {
  return api.put<User>(`/user`, user);
};

export const deleteUsersByIds = async (userIds: number[]) => {
  try {
    return await api.delete(`/users`, { data: userIds });
  } catch (error: any) {
    throw error.response || { message: 'Unknown error occurred' };
  }
};

export default {
  fetchUsers,
  fetchUserById,
  addNewUser,
  updateExistingUser,
  deleteUsersByIds,
};
