import React, { useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import { Box, Button, TextField, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Dictionary } from '../../models';
import { useAppDispatch, useAppSelector } from '../../store';
import { getDictionaryById, addDictionary, updateDictionary } from '../../store/slices/dictionarySlice';
import { selectIsGetDictionaryByIdLoading, selectSelectedDictionaryToUpdate } from '../../store/selectors/dictionariesSelectors';
import { toast, ToastContainer } from 'react-toastify';

export const DictionaryPage: React.FC = () => {
  const [dictionary, setDictionary] = useState<Dictionary>({});

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isEditMode = !!id;
  const existingDictionary = useAppSelector(selectSelectedDictionaryToUpdate);
  const isLoadingDictionary = useAppSelector(selectIsGetDictionaryByIdLoading);

  useEffect(() => {
    if (isEditMode && id) {
      dispatch(getDictionaryById(Number(id)));
    }
  }, [dispatch, id, isEditMode]);

  useEffect(() => {
    if (existingDictionary && isEditMode) {
      setDictionary({
        text: existingDictionary.text,
        level: existingDictionary.level,
      });
    }
  }, [existingDictionary, isEditMode]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDictionary({ ...dictionary, [name]: value });
  };

  const handleSelectChange = (e: SelectChangeEvent<number>) => {
    const { name, value } = e.target;
    setDictionary({ ...dictionary, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isEditMode) {
      const resultAction = await dispatch(updateDictionary({ id: Number(id), dictionary }));

      if (updateDictionary.fulfilled.match(resultAction)) {
        toast.success('Словарь был успешно отредактирован');
        navigate('/dictionary');
      } else {
        toast.error(`Ошибка: ${resultAction.error}`);
      }
    } else {
      const resultAction = await dispatch(addDictionary(dictionary));

      if (addDictionary.fulfilled.match(resultAction)) {
        toast.success('Словарь был успешно добавлен');
        navigate('/dictionary');
      } else {
        toast.error(`Ошибка: ${resultAction.error?.message}`);
      }
    }
  };

  return isEditMode && isLoadingDictionary ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxWidth: 400,
        margin: '0 auto',
        padding: '24px',
      }}
    >
      <ToastContainer />
      <TextField label="Текст" name="text" value={dictionary.text || ''} onChange={handleChange} required />
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="level-label">Класс</InputLabel>
        <Select
          labelId="level-label"
          label="Класс"
          variant="outlined"
          name="level"
          onChange={handleSelectChange}
          value={dictionary.level || ''}
          MenuProps={{ PaperProps: { sx: { maxHeight: 160 } } }}
          required
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((validNote) => (
            <MenuItem key={validNote} value={validNote}>
              {validNote}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button type="submit" variant="contained" color="primary">
        {isEditMode ? 'Обновить словарь' : 'Добавить словарь'}
      </Button>
    </Box>
  );
};
