import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPostings, updateExistingPosting } from '../../services/postingService';
import { Posting } from '../../models/Posting';

export interface PostingsState {
  postings: Posting[];
  totalRows: number;
  isPostingsLoading: boolean;
  getPostingsError: string | null;
  isPostingsUpdating: boolean;
  getPostingUpdateError: string | null;
}

const initialState: PostingsState = {
  postings: [],
  totalRows: 0,
  isPostingsLoading: false,
  getPostingsError: null,
  isPostingsUpdating: false,
  getPostingUpdateError: null,
};

export const getPostings = createAsyncThunk('postings/table', async (payload: any, thunkAPI) => {
  try {
    const res = await fetchPostings(payload);
    return res;
  } catch (error: unknown) {
    return thunkAPI.rejectWithValue('Failed to fetch postings');
  }
});

export const updatePosting = createAsyncThunk('postings/updatePosting', async (posting: Posting, thunkAPI) => {
  try {
    const response = await updateExistingPosting(posting);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to update posting');
  }
});

const postingsSlice = createSlice({
  name: 'postings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPostings.pending, (state) => {
        state.isPostingsLoading = true;
      })
      .addCase(getPostings.fulfilled, (state, action) => {
        const res = action.payload;
        state.isPostingsLoading = false;
        state.postings = res.rows;
        state.totalRows = res?.totalRows;
      })
      .addCase(getPostings.rejected, (state, action) => {
        state.isPostingsLoading = false;
        state.getPostingsError = action.payload as string;
      })
      // update
      .addCase(updatePosting.pending, (state) => {
        state.isPostingsUpdating = true;
      })
      .addCase(updatePosting.fulfilled, (state) => {
        state.isPostingsUpdating = false;
      })
      .addCase(updatePosting.rejected, (state, action) => {
        state.isPostingsUpdating = false;
        state.getPostingUpdateError = action.payload as string;
      });
  },
});

export default postingsSlice.reducer;
