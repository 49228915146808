import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import authReducer from './slices/authSlice';
import usersReducer from './slices/usersSlice';
import inboundsReducer from './slices/inboundsSlice';
import outboundsReducer from './slices/outboundsSlice';
import postingsReducer from './slices/postingsSlice';
import dictionariesReducer from './slices/dictionarySlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    usersState: usersReducer,
    inboundsState: inboundsReducer,
    outboundsState: outboundsReducer,
    postingsState: postingsReducer,
    dictionariesState: dictionariesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
