import React, { useCallback, useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Tooltip } from '@mui/material';
import { GridColDef, GridFilterItem, GridPaginationModel, GridRenderCellParams, GridSortItem } from '@mui/x-data-grid-pro';
import { CONSTANTS } from '../../models';
import { SharedTable } from '../../components/sharedTable';
import { useAppDispatch } from '../../store';
import {
  selectInbounds,
  selectIsFinishInboundLoading,
  selectIsInboundsLoading,
  selectTotalRowInbounds,
} from '../../store/selectors/inboundsSelector';
import { getInbounds, updateInboundFinishedAt, finishInbounds } from '../../store/slices/inboundsSlice';
import { getStoredItem, removeStoredItem, storeItem } from '../../services/storageService';
import { Inbound, OzonStatus } from '../../models/Inbound';
import { getStateSnapshotFromLocalStorage } from '../../utils/state';

const tableName = 'inbounds';
const defaultSorting: GridSortItem[] = [{ field: 'id', sort: 'desc' }];
const defaultPagination: Partial<GridPaginationModel> = { page: 0, pageSize: 25 };
const defaultFilter: GridFilterItem[] = [
  {
    field: 'ozon_status',
    operator: 'isAnyOf',
    value: ['sent', 'formed', 'arrived'],
  },
];

export const InboundsPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const inbounds = useSelector(selectInbounds);
  const isFinishInboundLoading = useSelector(selectIsFinishInboundLoading);
  const isInboundsLoading = useSelector(selectIsInboundsLoading);
  const totalRows = useSelector(selectTotalRowInbounds);

  const [isDataNededToBeFetched, setIsDataNededToBeFetched] = useState(true);
  const [isPaginationChange, setIsPaginationChange] = useState(false);
  const [selectedInboundIds, setSelectedInboundIds] = useState<number[]>([]);

  const ozonStatusTitlePair: { [key in OzonStatus]: string } = {
    formed: 'Сформирована',
    arrived: 'Прибыла на склад',
    finished: 'Завершена приемка',
    sent: 'Отправлена',
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      hideable: false,
    },
    {
      field: 'ozon_status',
      headerName: 'Статус Ozon',
      type: 'singleSelect',
      valueOptions: Object.entries(ozonStatusTitlePair).map((entry) => ({
        label: entry[1],
        value: entry[0],
      })),
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={`Обновлено: ${new Date(params.row.ozon_data_fetched_at).toLocaleString('ru-RU')}`}>
          <span>{ozonStatusTitlePair[params.value as OzonStatus]}</span>
        </Tooltip>
      ),
    },
    {
      field: 'postings_count',
      headerName: 'Отправлений',
      type: 'number',
    },
    {
      field: 'scanned_count',
      headerName: 'Сканировано',
      type: 'number',
    },
    {
      field: 'not_scanned_count',
      headerName: 'Не сканировано',
      type: 'number',
    },
    {
      field: 'ozon_start_point_name',
      headerName: 'Отправитель',
      type: 'string',
    },
    {
      field: 'ozon_sent_at',
      headerName: 'Дата отправки',
      type: 'dateTime',
    },
    {
      field: 'ozon_arrived_at',
      headerName: 'Дата прибытия',
      type: 'dateTime',
    },
    {
      field: 'ozon_finished_at',
      headerName: 'Дата завершения',
      type: 'dateTime',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.row.finished_at ? `У нас: ${new Date(params.row.finished_at).toLocaleDateString('ru-RU')}` : ''}>
          <span>{params.value ? new Date(params.value).toLocaleDateString('ru-RU') : ''}</span>
        </Tooltip>
      ),
    },
  ];

  const handleSelectionChange = useCallback(
    (pageSelectedRowIds: number[]) => {
      if (isPaginationChange) {
        setIsPaginationChange(false);
        return;
      }

      setSelectedInboundIds(pageSelectedRowIds);
      storeItem(CONSTANTS.selectedInboundIds, pageSelectedRowIds);
    },
    [selectedInboundIds, isPaginationChange]
  );

  const handlePaginationChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
    setIsPaginationChange(true);
  }, []);

  const handleFinishInbound = useCallback(async () => {
    const wrappedIds = selectedInboundIds.map((id) => ({ id }));
    const resultAction = await dispatch(finishInbounds(wrappedIds));

    if (finishInbounds.fulfilled.match(resultAction)) {
      const mapped = resultAction.payload.list.reduce((acc: { [key: number]: string }, inbound: Inbound) => {
        acc[inbound.id] = inbound.finished_at ?? '';
        return acc;
      }, {});
      dispatch(updateInboundFinishedAt(mapped));
      toast.success('Успешно обновлены!');
      setSelectedInboundIds([]);
      removeStoredItem(CONSTANTS.selectedInboundIds);
    }
  }, [dispatch, selectedInboundIds]);

  const dispatchGetInbounds = useCallback(async () => {
    const snapshotState = await getStateSnapshotFromLocalStorage(tableName);
    const resultAction = await dispatch(
      getInbounds({
        filtering: snapshotState?.filter?.filterModel?.items ?? defaultFilter,
        sorting: snapshotState?.sorting?.sortModel ?? defaultSorting,
        ...(snapshotState?.pagination?.paginationModel ?? defaultPagination),
      })
    );

    if (getInbounds.fulfilled.match(resultAction)) {
      setIsDataNededToBeFetched(false);
      const storedSelectedRowIds = getStoredItem<number[]>(CONSTANTS.selectedInboundIds);
      if (storedSelectedRowIds) {
        setSelectedInboundIds(storedSelectedRowIds);
      }
    }
  }, [defaultFilter, defaultSorting, defaultPagination]);

  const handleDataQueryChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
  }, []);

  useEffect(() => {
    if (isDataNededToBeFetched) {
      dispatchGetInbounds();
    }
  }, [isDataNededToBeFetched]);

  return (
    <div>
      <ToastContainer />
      <Box sx={{ display: 'flex', gap: '12px' }}>
        {selectedInboundIds.length > 0 && (
          <Button
            variant="contained"
            color="info"
            onClick={handleFinishInbound}
            disabled={isFinishInboundLoading}
            startIcon={isFinishInboundLoading ? <CircularProgress size={16} /> : null}
          >
            {isFinishInboundLoading ? 'Обновление...' : 'Завершить приемку'}
          </Button>
        )}
      </Box>
      <SharedTable
        columns={columns}
        disableMultipleRowSelection={true}
        loading={isInboundsLoading}
        isRowSelectable={(params) => params.row.finished_at === null}
        onPaginationChange={handlePaginationChange}
        onSelectionChange={handleSelectionChange}
        onFilterModelChange={handleDataQueryChange}
        onSortModelChange={handleDataQueryChange}
        rowCount={totalRows}
        rows={inbounds}
        selectedRowIds={selectedInboundIds}
        tableName={tableName}
      />
    </div>
  );
};
