import React from 'react';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { ruRU } from '@mui/x-data-grid/locales';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { CircularProgress } from '@mui/material';

import { getStateSnapshotFromLocalStorage } from '../../utils/state';

const CustomToolbar = () => {
  return (
    <GridToolbarContainer style={{ paddingTop: 10 }}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
};

interface SharedTableProps {
  columns: GridColDef[];
  disableMultipleRowSelection?: boolean;
  disableColumnSelector?: boolean;
  isRowSelectable?: (params: GridRowParams) => boolean;
  loading: boolean;
  onFilterModelChange?: (filterModel: GridFilterModel) => void;
  onPaginationChange?: (paginationModel: GridPaginationModel) => void;
  onSelectionChange?: (selectedRows: number[]) => void;
  onSortModelChange?: (sortModel: GridSortModel) => void;
  rowCount: number;
  rows: any[];
  selectedRowIds?: number[];
  tableName?: string;
}

function saveStateSnapshotToLocalStorage(tableName: string, stateSnapshot: GridInitialStatePro) {
  localStorage.setItem(tableName + 'DataGridState', JSON.stringify(stateSnapshot));
}

export const SharedTable: React.FC<SharedTableProps> = ({
  columns,
  disableMultipleRowSelection = false,
  disableColumnSelector = false,
  isRowSelectable,
  loading = false,
  onFilterModelChange,
  onPaginationChange,
  onSortModelChange,
  onSelectionChange,
  rowCount,
  rows,
  selectedRowIds = [],
  tableName = 'table',
}) => {
  const apiRef = useGridApiRef();

  const [cachedInitialState, setCachedInitialState] = React.useState<GridInitialStatePro>();
  const [orderedFields, setOrderedFields] = React.useState<GridColDef[]>([]);

  React.useEffect(() => {
    const stateSnapshot = getStateSnapshotFromLocalStorage(tableName);

    if (stateSnapshot) {
      if (stateSnapshot.pinnedColumns?.left) {
        stateSnapshot.pinnedColumns.left = stateSnapshot.pinnedColumns.left.filter((x) => x !== GRID_CHECKBOX_SELECTION_FIELD);
        stateSnapshot.pinnedColumns.left.unshift(GRID_CHECKBOX_SELECTION_FIELD);
      } else {
        stateSnapshot.pinnedColumns = { left: [GRID_CHECKBOX_SELECTION_FIELD] };
      }
      setCachedInitialState(stateSnapshot);
      setOrderedFields(
        columns
          .sort(
            (a, b) => (stateSnapshot.columns?.orderedFields?.indexOf(a.field) ?? 0) - (stateSnapshot.columns?.orderedFields?.indexOf(b.field) ?? 0)
          )
          .map((x) => {
            x.align = 'center';
            x.headerAlign = 'center';
            if (x.type === 'number' && !x.renderCell) {
              x.renderCell = (params: GridRenderCellParams) => <span>{params.value}</span>;
            }
            if (x.type === 'dateTime' && !x.valueGetter) {
              x.valueGetter = (value) => (value ? new Date(value) : '');
            }
            return x;
          })
      );
    }
  }, [apiRef, tableName]);

  const handleStateChange = React.useCallback(() => {
    if (loading) return;
    if (apiRef.current == null) return;
    const stateSnapshot = apiRef.current.exportState();
    saveStateSnapshotToLocalStorage(tableName, stateSnapshot);
  }, [apiRef, columns, tableName, loading]);

  if (apiRef.current == null) return <CircularProgress />;

  if (localStorage.getItem(tableName + 'DataGridState') && !cachedInitialState) {
    return <CircularProgress />;
  }

  return (
    <DataGridPro
      apiRef={apiRef}
      checkboxSelection
      columns={orderedFields}
      disableColumnSelector={disableColumnSelector}
      disableColumnSorting={false}
      disableDensitySelector
      disableMultipleColumnsSorting={false}
      disableMultipleRowSelection={disableMultipleRowSelection}
      disableRowSelectionOnClick
      filterMode="server"
      initialState={cachedInitialState}
      isRowSelectable={isRowSelectable}
      loading={loading}
      localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
      onFilterModelChange={onFilterModelChange}
      onPaginationModelChange={onPaginationChange}
      onRowSelectionModelChange={(newSelection: GridRowSelectionModel) => {
        if (onSelectionChange) {
          onSelectionChange(newSelection as number[]);
        }
      }}
      onSortModelChange={onSortModelChange}
      onStateChange={handleStateChange}
      pageSizeOptions={[25, 50, 100, 250, 500, 1000]}
      pagination
      paginationMode="server"
      rowCount={rowCount}
      rows={rows}
      rowSelectionModel={selectedRowIds}
      sortingOrder={['asc', 'desc', null]}
      slots={{
        toolbar: CustomToolbar,
      }}
      sortingMode={'server'}
      style={{
        marginTop: 20,
        marginBottom: 20,
      }}
    />
  );
};
