import React from 'react';
import { Navigate } from 'react-router-dom';
import { getStoredItem } from '../../services/storageService';

interface ProtectedRouteProps {
  children: React.ReactElement;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const isAuthenticated = !!getStoredItem<string>('access-token');

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Note: if authenticated, allow access to the route
  return children;
};
