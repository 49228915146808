import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { DashboardLayoutWrapper } from '../../components/dashboardLayout';

export const HomePage: React.FC = () => {
  return (
    <Box>
      <DashboardLayoutWrapper>
        <Box sx={{ padding: 2 }}>
          <Outlet />
        </Box>
      </DashboardLayoutWrapper>
    </Box>
  );
};
