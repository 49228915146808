import React, { useCallback, useEffect, useState } from 'react';
import { GridColDef, GridFilterItem, GridPaginationModel, GridRenderCellParams, GridSortItem } from '@mui/x-data-grid-pro';
import { useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import {
  selectDictionaries,
  selectIsDeleteDictionariesLoading,
  selectIsDictionariesLoading,
  selectTotalRowDictionaries,
} from '../../store/selectors/dictionariesSelectors';
import { deleteDictionaries, getDictionaries } from '../../store/slices/dictionarySlice';
import { toast, ToastContainer } from 'react-toastify';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { SharedTable } from '../../components/sharedTable';
import { CONSTANTS } from '../../models';
import { getStoredItem, removeStoredItem, storeItem } from '../../services/storageService';
import { useNavigate } from 'react-router-dom';
import { getStateSnapshotFromLocalStorage } from '../../utils/state';

const tableName = 'dictionaries';
const defaultFilter: GridFilterItem[] = [];
const defaultSorting: GridSortItem[] = [{ field: 'id', sort: 'desc' }];
const defaultPagination: Partial<GridPaginationModel> = { page: 0, pageSize: 25 };

export const DictionariesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const dictionaries = useSelector(selectDictionaries);
  const isDictionariesLoading = useSelector(selectIsDictionariesLoading);
  const isDeleteDictionariesLoading = useSelector(selectIsDeleteDictionariesLoading);
  const totalRows = useSelector(selectTotalRowDictionaries);

  const [isDataNededToBeFetched, setIsDataNededToBeFetched] = useState(true);
  const [isPaginationChange, setIsPaginationChange] = useState(false);
  const [selectedDictionaryIds, setSelectedDictionaryIds] = useState<number[]>([]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
    },
    {
      field: 'text',
      headerName: 'Фраза',
      type: 'string',
    },
    {
      field: 'level',
      headerName: 'Класс',
      type: 'number',
    },
    {
      field: 'actions',
      headerName: 'Редактировать',
      renderCell: (params: GridRenderCellParams) => (
        <IconButton color="primary" onClick={() => navigate(`/dictionary/edit/${params.row.id}`)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const handlePaginationChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
    setIsPaginationChange(true);
  }, []);

  const handleSelectionChange = useCallback(
    (pageSelectedRowIds: number[]) => {
      if (isPaginationChange) {
        setIsPaginationChange(false);
        return;
      }
      setSelectedDictionaryIds(pageSelectedRowIds);
      storeItem(CONSTANTS.selectedDictionaryIds, pageSelectedRowIds);
    },
    [selectedDictionaryIds, isPaginationChange]
  );

  const handleAddDictionary = () => {
    navigate('/dictionary/create');
  };

  const handleDelete = useCallback(async () => {
    const resultAction = await dispatch(deleteDictionaries(selectedDictionaryIds as number[]));

    if (deleteDictionaries.fulfilled.match(resultAction)) {
      toast.success('Пользователи успешно удалены!');
      setSelectedDictionaryIds([]);
      dispatchGetDictionaries();
      removeStoredItem(CONSTANTS.selectedDictionaryIds);
    } else {
      toast.error(`Ошибка: ${resultAction.error}`);
    }
  }, [dispatch, selectedDictionaryIds]);

  const dispatchGetDictionaries = useCallback(async () => {
    const snapshotState = await getStateSnapshotFromLocalStorage(tableName);
    const resultAction = await dispatch(
      getDictionaries({
        filtering: snapshotState?.filter?.filterModel?.items ?? defaultFilter,
        sorting: snapshotState?.sorting?.sortModel ?? defaultSorting,
        ...(snapshotState?.pagination?.paginationModel ?? defaultPagination),
      })
    );

    if (getDictionaries.fulfilled.match(resultAction)) {
      setIsDataNededToBeFetched(false);
      const storedSelectedRowIds = getStoredItem<number[]>(CONSTANTS.selectedDictionaryIds);
      if (storedSelectedRowIds) {
        setSelectedDictionaryIds(storedSelectedRowIds);
      }
    }
  }, [defaultFilter, defaultSorting, defaultPagination]);

  const handleDataQueryChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
  }, []);

  useEffect(() => {
    if (isDataNededToBeFetched) {
      dispatchGetDictionaries();
    }
  }, [isDataNededToBeFetched]);

  return (
    <div>
      <ToastContainer />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px',
        }}
      >
        <Typography variant="h4">Справочник</Typography>
        <Box sx={{ display: 'flex', gap: '12px' }}>
          {selectedDictionaryIds.length > 0 && (
            <Button
              variant="contained"
              color="error"
              onClick={handleDelete}
              disabled={isDeleteDictionariesLoading}
              startIcon={isDeleteDictionariesLoading ? <CircularProgress size={16} /> : null}
            >
              {isDeleteDictionariesLoading ? 'Удаления...' : 'Удалить выбранные'}
            </Button>
          )}
          <Button variant="contained" color="primary" onClick={handleAddDictionary}>
            Добавить
          </Button>
        </Box>
      </Box>
      <SharedTable
        columns={columns}
        disableColumnSelector={true}
        loading={isDictionariesLoading}
        onPaginationChange={handlePaginationChange}
        onSelectionChange={handleSelectionChange}
        onFilterModelChange={handleDataQueryChange}
        onSortModelChange={handleDataQueryChange}
        rowCount={totalRows}
        rows={dictionaries}
        selectedRowIds={selectedDictionaryIds}
        tableName={tableName}
      />
    </div>
  );
};
