import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';

export function getStateSnapshotFromLocalStorage(tableName: string) {
  const stringifiedState = localStorage.getItem(tableName + 'DataGridState');
  if (!stringifiedState) {
    return undefined;
  }
  const stateSnapshot = JSON.parse(stringifiedState) as GridInitialStatePro;
  return stateSnapshot;
}
