import { createSelector } from 'reselect';
import { RootState } from '../index';
import { AuthState } from '../slices/authSlice';

const selectAuthState = (state: RootState): AuthState => state.auth;

export const selectIsAuthenticated = createSelector([selectAuthState], (authState) => authState.isAuthenticated);

export const selectIsUserLoggingIn = createSelector([selectAuthState], (authState) => authState.isLoggingIn);

export const selectError = createSelector([selectAuthState], (authState) => authState.error);
