import React, { useCallback, useState } from 'react';
import { TextField, Button, Box, Typography, Container, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import { login } from '../../store/slices/authSlice';
import { useSelector } from 'react-redux';
import { selectIsUserLoggingIn, selectError } from '../../store/selectors/authSelectors';
import { toast, ToastContainer } from 'react-toastify';

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const isUserLoggingIn = useSelector(selectIsUserLoggingIn);
  const error = useSelector(selectError);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      const resultAction = await dispatch(login({ email, password }));

      if (login.fulfilled.match(resultAction)) {
        navigate('/');
      } else {
        console.error(resultAction.error);
        toast.error(`Ошибка: ${resultAction.error}`);
      }
    },
    [dispatch, navigate, email, password]
  );

  return (
    <Container maxWidth="sm">
      <ToastContainer />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 8,
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Логин
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Эл.почта"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2, gap: '12px' }} disabled={isUserLoggingIn}>
            Войти
            {isUserLoggingIn && <CircularProgress size={20} color="inherit" />}
          </Button>
          {error && <p>{error}</p>}
        </form>
      </Box>
    </Container>
  );
};
