import { createSelector } from 'reselect';
import { RootState } from '../index';
import { UsersState } from '../slices/usersSlice';

const selectUsersState = (state: RootState): UsersState => state.usersState;

export const selectUsersError = createSelector([selectUsersState], (usersState) => usersState.getUsersError);

export const selectUsers = createSelector([selectUsersState], (usersState) => usersState.users);

export const selectTotalRowUsers = createSelector([selectUsersState], (usersState) => usersState.totalRow);

export const selectIsUsersLoading = createSelector([selectUsersState], (usersState) => usersState.isUsersLoading);

export const selectIsAddUserLoading = createSelector([selectUsersState], (usersState) => usersState.isAddUserLoading);

export const selectSelectedUserToUpdate = createSelector([selectUsersState], (usersState) => usersState.selectedUserToUpdate);

export const selectIsGetUserByIdLoading = createSelector([selectUsersState], (usersState) => usersState.isGetUserByIdLoading);

export const selectIsUpdateUserLoading = createSelector([selectUsersState], (usersState) => usersState.isUpdateUserLoading);

export const selectIsDeleteUsersLoading = createSelector([selectUsersState], (usersState) => usersState.isDeleteUsersLoading);

export const selectGetUserByIdError = createSelector([selectUsersState], (usersState) => usersState.getUserByIdError);

export const selectUpdateUserError = createSelector([selectUsersState], (usersState) => usersState.updateUserError);

export const selectAddNewUserError = createSelector([selectUsersState], (usersState) => usersState.addNewUserError);

export const selectDeleteUsersError = createSelector([selectUsersState], (usersState) => usersState.deleteUsersError);
