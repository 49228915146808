import { Posting } from '../models/Posting';
import api from './apiClient';

export const fetchPostings = async (payload?: any) => {
  const response = await api.post('/postings/table', payload);
  return response.data;
};

export const updateExistingPosting = (posting: Posting) => {
  return api.put<Posting>(`/posting/${posting.id}`, posting);
};
