import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LoginPage } from '../pages/login/LoginPage';
import { HomePage } from '../pages/home/HomePage';
import { UsersPage } from '../pages/users/UsersPage';
import { ProtectedRoute } from '../components/protectedRoute';
import { UserPage } from '../pages/users/UserPage';
import { ReturnsPage } from '../pages/returns/ReturnsPage';
import { OutboundsPage } from '../pages/outbounds/OutboundsPage';
import { ReturnShipsPage } from '../pages/returnships/ReturnShipsPage';
import { PostingsPage } from '../pages/postings/PostingsPage';
import { DictionariesPage } from '../pages/dictionary';
import { DictionaryPage } from '../pages/dictionary/DictionaryPage';
import { InboundsPage } from '../pages/inbounds/InboundsPage';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        >
          <Route path="users" element={<UsersPage />} />
          <Route path="users/create" element={<UserPage />} />
          <Route path="users/edit/:id" element={<UserPage />} />
          <Route path="inbounds" element={<InboundsPage />} />
          <Route path="postings" element={<PostingsPage />} />
          <Route path="returns" element={<ReturnsPage />} />
          <Route path="returnships" element={<ReturnShipsPage />} />
          <Route path="dictionary" element={<DictionariesPage />} />
          <Route path="dictionary/create" element={<DictionaryPage />} />
          <Route path="dictionary/edit/:id" element={<DictionaryPage />} />
          <Route path="outbounds" element={<OutboundsPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
