import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Switch, FormControlLabel, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { User } from '../../models';
import { useAppDispatch, useAppSelector } from '../../store';
import { getUserById, addUser, updateUser } from '../../store/slices/usersSlice';
import { selectIsGetUserByIdLoading, selectSelectedUserToUpdate } from '../../store/selectors/usersSelectors';
import { toast } from 'react-toastify';

export const UserPage: React.FC = () => {
  const [user, setUser] = useState<User>({
    login: '',
    password: '',
    active: false,
  });

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isEditMode = !!id;
  const existingUser = useAppSelector(selectSelectedUserToUpdate);
  const isLoadingUser = useAppSelector(selectIsGetUserByIdLoading);

  useEffect(() => {
    if (isEditMode && id) {
      dispatch(getUserById(Number(id)));
    }
  }, [dispatch, id, isEditMode]);

  useEffect(() => {
    if (existingUser && isEditMode) {
      setUser({
        login: existingUser.login,
        active: existingUser.active,
      });
    }
  }, [existingUser, isEditMode]);

  // Handle input change for form fields
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  // Handle switch change for status
  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, active: e.target.checked });
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isEditMode) {
      const resultAction = await dispatch(updateUser({ id: Number(id), user }));

      if (updateUser.fulfilled.match(resultAction)) {
        toast.success('Пользователь был успешно отредактирован');
        navigate('/users');
      }
    } else {
      const resultAction = await dispatch(addUser(user));

      if (addUser.fulfilled.match(resultAction)) {
        toast.success('Пользователь был успешно добавлен');
        navigate('/users');
      }
    }
  };

  return isEditMode && isLoadingUser ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxWidth: 400,
        margin: '0 auto',
        padding: '24px',
      }}
    >
      <TextField label="Логин" name="login" value={user.login} onChange={handleChange} required />
      <TextField label="Пароль" name="password" type="text" value={user.password} onChange={handleChange} required />
      <FormControlLabel
        control={<Switch checked={user.active || false} onChange={handleSwitchChange} name="status" color="primary" />}
        label="Active"
      />
      <Button type="submit" variant="contained" color="primary">
        {isEditMode ? 'Обновить пользователя' : 'Добавить пользователя'}
      </Button>
    </Box>
  );
};
