import React, { useCallback, useEffect, useState } from 'react';
import { GridColDef, GridFilterItem, GridPaginationModel, GridRenderCellParams, GridSortItem } from '@mui/x-data-grid-pro';
import { useAppDispatch } from '../../store';
import { getPostings } from '../../store/slices/postingsSlice';
import { CONSTANTS } from '../../models';
import { ToastContainer } from 'react-toastify';
import { Tooltip } from '@mui/material';
import { SharedTable } from '../../components/sharedTable';
import { selectIsPostingsLoading, selectPostings, selectTotalRowPostings } from '../../store/selectors/postingSelector';
import { useSelector } from 'react-redux';
import { PostingReturnReason } from '../../models/Posting';
import { getStoredItem, storeItem } from '../../services/storageService';
import { getStateSnapshotFromLocalStorage } from '../../utils/state';

const tableName = 'returns';
const defaultSorting: GridSortItem[] = [{ field: 'id', sort: 'desc' }];
const defaultPagination: Partial<GridPaginationModel> = { page: 0, pageSize: 25 };
const defaultFilter: GridFilterItem[] = [];
const hiddenFilter: GridFilterItem[] = [
  {
    id: 'cargoTypeIsSendback',
    field: 'cargo_type',
    operator: 'is',
    value: 'sendback',
  },
];

export const ReturnsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const postings = useSelector(selectPostings);
  const isPostingsLoading = useSelector(selectIsPostingsLoading);
  const totalRows = useSelector(selectTotalRowPostings);

  const [isDataNededToBeFetched, setIsDataNededToBeFetched] = useState(true);
  const [isPaginationChange, setIsPaginationChange] = useState(false);

  const [selectedPostingIds, setSelectedPostingIds] = useState<number[]>([]);

  const postingReturnReasonTitlePair: { [key in PostingReturnReason]: string } = {
    Damage: 'Повреждение',
    Hazard: 'Опасность',
    Surplus: 'Излишек',
    ReturnOnDelivery: 'Запрос Ozon',
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      hideable: false,
    },
    {
      field: 'return_reason',
      headerName: 'Причина возврата',
      type: 'singleSelect',
      valueOptions: Object.entries(postingReturnReasonTitlePair).map((entry) => ({
        label: entry[1],
        value: entry[0],
      })),
      hideable: true,
    },
    {
      field: 'ozon_return_point_name',
      headerName: 'Место возврата',
      type: 'string',
    },
    {
      field: 'ozon_exemplars',
      headerName: 'Состав',
      valueGetter: (_, row) =>
        row.ozon_exemplars
          ?.map((o: { name: string }) => o.name)
          .slice(0, 2)
          .join('\n') ?? '',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.row.ozon_exemplars?.map((o: { name: string }) => o.name).join('\n') ?? ''}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'ozon_inbound_id',
      headerName: 'Входящая поставка',
      type: 'number',
    },
    {
      field: 'outbound_id',
      headerName: 'Возвратная поставка',
      type: 'number',
    },
    {
      field: 'last_scan_at',
      headerName: 'Последнее сканирование',
      type: 'dateTime',
    },
    {
      field: 'return_ordered_at',
      headerName: 'Дата возврата',
      type: 'dateTime',
      renderCell: (params) => (
        <Tooltip title={params.row.ozon_return_at ? `В озон:\n ${new Date(params.row.ozon_return_at).toLocaleDateString('ru-RU')}` : ''}>
          <span>{params.value ? new Date(params.value).toLocaleString('ru-RU') : ''}</span>
        </Tooltip>
      ),
    },
  ];

  const handleSelectionChange = useCallback(
    (pageSelectedRowIds: number[]) => {
      if (isPaginationChange) {
        setIsPaginationChange(false);
        return;
      }

      setSelectedPostingIds(pageSelectedRowIds);
      storeItem(CONSTANTS.selectedPostingIds, pageSelectedRowIds);
    },
    [postings, selectedPostingIds, isPaginationChange]
  );

  const handlePaginationChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
    setIsPaginationChange(true);
  }, []);

  const dispatchGetPostings = useCallback(async () => {
    const snapshotState = await getStateSnapshotFromLocalStorage(tableName);
    const resultAction = await dispatch(
      getPostings({
        filtering: [snapshotState?.filter?.filterModel?.items ?? defaultFilter, hiddenFilter].flat(),
        sorting: snapshotState?.sorting?.sortModel ?? defaultSorting,
        ...(snapshotState?.pagination?.paginationModel ?? defaultPagination),
      })
    );

    if (getPostings.fulfilled.match(resultAction)) {
      setIsDataNededToBeFetched(false);
      const storedSelectedRowIds = getStoredItem<number[]>(CONSTANTS.selectedPostingIds);
      if (storedSelectedRowIds) {
        setSelectedPostingIds(storedSelectedRowIds);
      }
    }
  }, [defaultFilter, defaultSorting, defaultPagination]);

  const handleDataQueryChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
  }, []);

  useEffect(() => {
    if (isDataNededToBeFetched) {
      dispatchGetPostings();
    }
  }, [isDataNededToBeFetched]);

  return (
    <div>
      <ToastContainer />

      <SharedTable
        columns={columns}
        loading={isPostingsLoading}
        onFilterModelChange={handleDataQueryChange}
        onPaginationChange={handlePaginationChange}
        onSelectionChange={handleSelectionChange}
        onSortModelChange={handleDataQueryChange}
        rowCount={totalRows}
        rows={postings}
        selectedRowIds={selectedPostingIds}
        tableName="returns"
      />
    </div>
  );
};
