import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchOutbounds,
  requestOutboundsMeasure,
  requestOutboundsPackAction,
  requestOutboundsSentAction,
  requestReturnshipsSentAction,
} from '../../services/outboundService';
import { Outbound } from '../../models/Outbound';

export interface OutboundsState {
  outbounds: Outbound[];
  totalRows: number;

  waitTableFetch: boolean;
  tableFetchError: string | null;

  waitUpdate: boolean;
  updateError: string | null;

  waitSend: boolean;
  sendError: string | null;

  waitMeasure: boolean;
  mesureError: string | null;

  waitPack: boolean;
  packError: string | null;
}

const initialState: OutboundsState = {
  outbounds: [],
  totalRows: 0,

  waitTableFetch: false,
  tableFetchError: null,

  waitUpdate: false,
  updateError: null,

  waitSend: false,
  sendError: null,

  waitMeasure: false,
  mesureError: null,

  waitPack: false,
  packError: null,
};

export const getOutbounds = createAsyncThunk('outbounds/table', async (payload: any, thunkAPI) => {
  try {
    const res = await fetchOutbounds(payload);
    return res;
  } catch (error: unknown) {
    return thunkAPI.rejectWithValue('Failed to fetch outbounds');
  }
});

export const measureOutbounds = createAsyncThunk('outbounds/measure', async (payload: any, thunkAPI) => {
  try {
    const response = await requestOutboundsMeasure(payload);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to send outbound measurements');
  }
});

export const sendOutbounds = createAsyncThunk('outbounds/send', async (payload: any, thunkAPI) => {
  try {
    const response = await requestOutboundsSentAction(payload);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to send outbounds');
  }
});

export const packOutbounds = createAsyncThunk('outbounds/pack', async (payload: any, thunkAPI) => {
  try {
    const response = await requestOutboundsPackAction(payload);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to pack outbound');
  }
});

export const sendReturnships = createAsyncThunk('outbounds/return', async (payload: any, thunkAPI) => {
  try {
    const response = await requestReturnshipsSentAction(payload);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to send outbounds');
  }
});

const outboundsSlice = createSlice({
  name: 'outbounds',
  initialState,
  reducers: {
    outboundMeasureReducer(state, action) {
      const mapped = action.payload;
      state.outbounds = state.outbounds.map((outbound) =>
        mapped[outbound.id]
          ? {
              ...outbound,
              measurements: mapped[outbound.id],
            }
          : outbound
      );
    },
    outboundsSendReducer(state, action) {
      const mapped = action.payload;
      state.outbounds = state.outbounds.map((outbound) =>
        mapped[outbound.id]
          ? {
              ...outbound,
              sent_at: mapped[outbound.id],
            }
          : outbound
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOutbounds.pending, (state) => {
        state.waitTableFetch = true;
      })
      .addCase(getOutbounds.fulfilled, (state, action) => {
        const res = action.payload;
        state.waitTableFetch = false;
        state.outbounds = res.rows;
        state.totalRows = res?.totalRows;
      })
      .addCase(getOutbounds.rejected, (state, action) => {
        state.waitTableFetch = false;
        state.tableFetchError = action.payload as string;
      })
      // update finished at
      .addCase(measureOutbounds.pending, (state) => {
        state.waitUpdate = true;
      })
      .addCase(measureOutbounds.fulfilled, (state) => {
        state.waitMeasure = false;
      })
      .addCase(measureOutbounds.rejected, (state, action) => {
        state.waitUpdate = false;
        state.mesureError = action.payload as string;
      })
      // send finished at
      .addCase(sendOutbounds.pending, (state) => {
        state.waitSend = true;
      })
      .addCase(sendOutbounds.fulfilled, (state) => {
        state.waitSend = false;
      })
      .addCase(sendOutbounds.rejected, (state, action) => {
        state.waitSend = false;
        state.sendError = action.payload as string;
      })
      // pack outbounds
      .addCase(packOutbounds.pending, (state) => {
        state.waitPack = true;
      })
      .addCase(packOutbounds.fulfilled, (state) => {
        state.waitPack = false;
      })
      .addCase(packOutbounds.rejected, (state, action) => {
        state.waitPack = false;
        state.packError = action.payload as string;
      })
      // send returnships
      .addCase(sendReturnships.pending, (state) => {
        state.waitSend = true;
      })
      .addCase(sendReturnships.fulfilled, (state) => {
        state.waitSend = false;
      })
      .addCase(sendReturnships.rejected, (state, action) => {
        state.waitSend = false;
        state.sendError = action.payload as string;
      });
  },
});

export const { outboundMeasureReducer, outboundsSendReducer } = outboundsSlice.actions;
export default outboundsSlice.reducer;
