import { createSelector } from 'reselect';
import { RootState } from '../index';
import { DictionariesState } from '../slices/dictionarySlice';

const selectDictionariesState = (state: RootState): DictionariesState => state.dictionariesState;

export const selectDictionariesError = createSelector([selectDictionariesState], (dictionariesState) => dictionariesState.getDictionariesError);

export const selectDictionaries = createSelector([selectDictionariesState], (dictionariesState) => dictionariesState.dictionaries);

export const selectTotalRowDictionaries = createSelector([selectDictionariesState], (dictionariesState) => dictionariesState.totalRow);

export const selectIsDictionariesLoading = createSelector([selectDictionariesState], (dictionariesState) => dictionariesState.isDictionariesLoading);

export const selectIsAddDictionaryLoading = createSelector(
  [selectDictionariesState],
  (dictionariesState) => dictionariesState.isAddDictionaryLoading
);

export const selectSelectedDictionaryToUpdate = createSelector(
  [selectDictionariesState],
  (dictionariesState) => dictionariesState.selectedDictionaryToUpdate
);

export const selectIsGetDictionaryByIdLoading = createSelector(
  [selectDictionariesState],
  (dictionariesState) => dictionariesState.isGetDictionaryByIdLoading
);

export const selectIsUpdateDictionaryLoading = createSelector(
  [selectDictionariesState],
  (dictionariesState) => dictionariesState.isUpdateDictionaryLoading
);

export const selectIsDeleteDictionariesLoading = createSelector(
  [selectDictionariesState],
  (dictionariesState) => dictionariesState.isDeleteDictionariesLoading
);

export const selectGetDictionaryByIdError = createSelector(
  [selectDictionariesState],
  (dictionariesState) => dictionariesState.getDictionaryByIdError
);

export const selectUpdateDictionaryError = createSelector([selectDictionariesState], (dictionariesState) => dictionariesState.updateDictionaryError);

export const selectAddNewDictionaryError = createSelector([selectDictionariesState], (dictionariesState) => dictionariesState.addNewDictionaryError);

export const selectDeleteDictionariesError = createSelector(
  [selectDictionariesState],
  (dictionariesState) => dictionariesState.deleteDictionariesError
);
