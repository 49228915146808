import { createSelector } from 'reselect';
import { RootState } from '../index';
import { PostingsState } from '../slices/postingsSlice';

const selectPostingsState = (state: RootState): PostingsState => state.postingsState;

export const selectPostings = createSelector([selectPostingsState], (postingsState) => postingsState.postings);

export const selectPostingsError = createSelector([selectPostingsState], (postingsState) => postingsState.getPostingsError);

export const selectTotalRowPostings = createSelector([selectPostingsState], (postingsState) => postingsState.totalRows);

export const selectIsPostingsLoading = createSelector([selectPostingsState], (postingsState) => postingsState.isPostingsLoading);
